
/* @font-face {
    font-family: 'Montserrat';
    src: url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-ExtraBold.eot");
    src: local("Montserrat ExtraBold"),local("Montserrat-ExtraBold"),url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-ExtraBold.eot?#iefix") format("embedded-opentype"),url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-ExtraBold.woff2") format("woff2"),url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-ExtraBold.woff") format("woff"),url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-ExtraBold.ttf") format("truetype"),url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-ExtraBold.svg#Montserrat-ExtraBold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: 'Montserrat';
    src: url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-Black.eot");
    src: local("Montserrat Black"),local("Montserrat-Black"),url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-Black.eot?#iefix") format("embedded-opentype"),url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-Black.woff2") format("woff2"),url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-Black.woff") format("woff"),url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-Black.ttf") format("truetype"),url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-Black.svg#Montserrat-Black") format("svg");
    font-weight: 900;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: 'Montserrat';
    src: url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-Bold.eot");
    src: local("Montserrat Bold"),local("Montserrat-Bold"),url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-Bold.eot?#iefix") format("embedded-opentype"),url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-Bold.woff2") format("woff2"),url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-Bold.woff") format("woff"),url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-Bold.ttf") format("truetype"),url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-Bold.svg#Montserrat-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: 'Montserrat';
    src: url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-Regular.eot");
    src: local("Montserrat Regular"),local("Montserrat-Regular"),url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-Regular.eot?#iefix") format("embedded-opentype"),url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-Regular.woff2") format("woff2"),url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-Regular.woff") format("woff"),url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-Regular.ttf") format("truetype"),url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-Regular.svg#Montserrat-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: 'Montserrat';
    src: url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-Thin.eot");
    src: local("Montserrat Thin"),local("Montserrat-Thin"),url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-Thin.eot?#iefix") format("embedded-opentype"),url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-Thin.woff2") format("woff2"),url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-Thin.woff") format("woff"),url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-Thin.ttf") format("truetype"),url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-Thin.svg#Montserrat-Thin") format("svg");
    font-weight: 100;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: 'Montserrat';
    src: url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-SemiBold.eot");
    src: local("Montserrat SemiBold"),local("Montserrat-SemiBold"),url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-SemiBold.eot?#iefix") format("embedded-opentype"),url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-SemiBold.woff2") format("woff2"),url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-SemiBold.woff") format("woff"),url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-SemiBold.ttf") format("truetype"),url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-SemiBold.svg#Montserrat-SemiBold") format("svg");
    font-weight: 600;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: 'Montserrat';
    src: url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-Medium.eot");
    src: local("Montserrat Medium"),local("Montserrat-Medium"),url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-Medium.eot?#iefix") format("embedded-opentype"),url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-Medium.woff2") format("woff2"),url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-Medium.woff") format("woff"),url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-Medium.ttf") format("truetype"),url("https://capabilities.thegrovery.com/wp-content/themes/mota9-child/assets/fonts/Montserrat-Medium.svg#Montserrat-Medium") format("svg");
    font-weight: 500;
    font-style: normal;
    font-display: swap
} */

$primary: #e60023;
$white: #ffffff;
$lightgrey: #e9e9e9;
$blue: #007B98;
$red: #E76F51;
$orange: #FF9008;
$lightorange: #FFB364;
$yellow: #BEE330;
$neongreen: #BEE330;
$dimyellow: #E9C46A;
$teal: #00DFC3;
$green: #06D6A0;
$mint: #AEFFD8;
$darkgrey: #6C6C6C;
$grey: #808080;
$black: #222222;

@import url("https://use.typekit.net/cxv3qtn.css");

header {
    height: 100vh !important;
}
html {
    /* font-family: 'Montserrat', sans-serif !important; */
}
body {
    /* background-image: url("images/leaf-texture.png"); */
    footer {
        background-color: $teal;
        .social-links {
            a {
                color: $white;
            }
        }
        #go-top {
            a {
                background-color: $lightorange;
            }
        }
        .copyright {
            padding: 10px 0 20px;
            font-family: 'Montserrat', sans-serif;
            color: $white;
        }
    }
}
h1 {
    font-family: montserrat, sans-serif !important;
    font-weight: 900 !important;
    /* text-transform: uppercase; */
}
h3 {
    font-family: montserrat, sans-serif !important;
    font-weight: 700 !important;
    color: white !important;
    text-transform: uppercase;
}
.the-text {
    -webkit-text-stroke-width: 3px;
    font-style: normal;
    color: rgba(255,255,255,0);
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #fff;
}
.dot-text {
    color: #BEE330;
}
.co-text {
    color: #FFB364;
}
body {
    background: #007B98;
    
}
.particles {
    position: fixed;
}
.button {
    font-family: montserrat, sans-serif !important;
    font-weight: 600 !important;
}
.btn {
    margin: 10px!important;
}
.email-btn {
    background: #BEE330 !important;
}
.email-btn:hover {
    background: #9dbb27 !important;
}
.web-btn {
    background: #808080 !important;
}
.web-btn:hover {
    background: #575757 !important;
}
.button {
    /* background: #007B98; */
}
footer {
    /* background: #808080; */
    margin: 0;
}